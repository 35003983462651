import React, { useEffect, useState } from 'react';
import './styleform.css';
import { useLocation, useNavigate } from 'react-router';
import img1 from './img/Viplava text1.png';
import img2 from './img/viplava1.png';
import img3 from './img/viplava2.png';

const App = () => {
  const [activeIndex, setActiveIndex] = useState(1);
  const [email, setEmail] = useState('');
  const [name, setName] = useState('');
  const [city, setCity] = useState('');
  const [chanting, setChanting] = useState('');
  const [facilitator, setFacilitator] = useState('');
  const [facilitatorsList, setFacilitatorsList] = useState([]); // Store the list of facilitators
  const [initiatedName, setInitiatedName] = useState('');
  const [occupation, setOccupation] = useState('');
  const [dob, setDob] = useState('');
  const [date, setDate] = useState('');
  const [registeredBy, setRegisteredBy] = useState('');
  const location = useLocation();
  const [areasList, setAreasList] = useState([]);
  const [contact, setContact] = useState('');
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [emailError, setEmailError] = useState('');
  const [selectedOption, setSelectedOption] = useState('');
  const [isOption1Checked, setIsOption1Checked] = useState(false);
  const [isOption2Checked, setIsOption2Checked] = useState(false);
  const [promoCode, setPromoCode] = useState('');
  const [minDate, setMinDate] = useState('');
  const [maxDate, setMaxDate] = useState('');
  const navigate = useNavigate(); // Used to navigate to the payment page
  // useEffect(() => {
  //   const searchParams = new URLSearchParams(location.search);
  //   const promoCodeFromUrl = searchParams.get('promo');

  //   if (promoCodeFromUrl) {
  //     setPromoCode(promoCodeFromUrl);
  //     fetch(`http://localhost:5000/api/getEmailByPromoCode?promoCode=${promoCodeFromUrl}`)
  //       .then(response => response.json())
  //       .then(data => {
  //         if (data.success) {
  //           setRegisteredBy(data.email);
  //         } else {
  //           console.error('Invalid promo code');
  //         }
  //       })
  //       .catch(err => console.error('Error fetching email by promo code:', err));
  //   }
  // }, [location.search]);


  const getBaseURL = () => {
    const host = window.location.host;
  
    if (host.includes('localhost')) {
      return 'http://localhost:5000'; // Local API URL
    } else {
      return 'https://iyfghaziabad.in'; // Production API URL
    }
  };
  
  

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const promoCodeFromUrl = searchParams.get('promo');

    if (promoCodeFromUrl) {
      setPromoCode(promoCodeFromUrl);
      setRegisteredBy(promoCodeFromUrl); // Set promo code as registeredBy
    }
  }, [location.search]);



  // Handle change in radio button selection
  const handleOptionChange = (event) => {
    setSelectedOption(event.target.value);
  };
  useEffect(() => {
    const interval = setInterval(() => {
      setActiveIndex((prevIndex) => (prevIndex % 3) + 1);
    }, 4000); // Change image every 4 seconds

    return () => clearInterval(interval);
  }, []);

  // Fetch areas when the component mounts
  useEffect(() => {
    fetch(`${getBaseURL()}/api/getAllAreas`)
      .then(response => response.json())
      .then(data => {
        if (data.success) {
          setAreasList(data.areas);
        } else {
          setAreasList([]);
        }
      })
      .catch(err => console.error('Error fetching areas:', err));
  }, []);

  // Fetch facilitators when city/area is selected
  useEffect(() => {
    if (city) {
      fetch(`${getBaseURL()}/api/getFacilitatorsByArea?area=${city}`)
        .then(response => response.json())
        .then(data => {
          if (data.success) {
            setFacilitatorsList(data.facilitators);
            setFacilitator('');
          } else {
            setFacilitatorsList([]);
            setFacilitator(''); // Clear the facilitator field if no facilitators found
          }
        })
        .catch(err => console.error('Error fetching facilitators:', err));
    }
  }, [city]);

  const handleChantingChange = (event) => {
    const value = event.target.value;
    if (/^\d*$/.test(value) && value <= 25) {  // Allows only numbers
      setChanting(value);
    }
  };
  useEffect(() => {
    const today = new Date();
    const minAge = 15;
    const maxAge = 40;
    const minDate = new Date(today.getFullYear() - maxAge, today.getMonth(), today.getDate());
    const maxDate = new Date(today.getFullYear() - minAge, today.getMonth(), today.getDate());

    setMinDate(minDate.toISOString().split('T')[0]);
    setMaxDate(maxDate.toISOString().split('T')[0]);
  }, []);

  const handleContactChange = (event) => {
    const value = event.target.value;
    if (/^\d*$/.test(value) && value.length <= 10) {
      setContact(value);
    }
  };



  const handleSignup = async (event) => {
    event.preventDefault();

    // Check if the email is valid
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailPattern.test(email)) {
      setEmailError('Please enter a valid email address.');
      return;
    } else {
      setEmailError('');
    }

    // Check if the contact number is exactly 10 digits
    if (contact.length !== 10) {
      setError(true);
      setErrorMessage('Contact number must be exactly 10 digits.');
      return;
    }

    // Check if the "Do you want dhoti kurta?" option is selected
    if (!selectedOption) {
      setError(true);
      setErrorMessage('Please select whether you want dhoti kurta.');
      return;
    }
    // Chanting rounds validation
    if (chanting < 2 || chanting > 25) {
      setError(true);
      setErrorMessage('You cannot register. The minimum requirement is 2 rounds and the maximum is 25 rounds.');
      return;
    }

    console.log("Sending data:", { email, name, city, contact, chanting, facilitator, initiatedName, occupation, dob, selectedOption, registeredBy });
    // Check if any of the required fields are empty
    if (!email || !name || !city || !contact || !chanting || !facilitator || !occupation || !dob || !selectedOption) {
      setError(true);
      setErrorMessage('Please fill in all required fields.');
      return;
    } else {
      setError(false);
      setErrorMessage('');
    }
    try {
      // Check if the email is already registered
      let checkResponse = await fetch(`${getBaseURL()}/api/checkEmail`, {
        method: "POST",
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ email })
      });

      let checkResult = await checkResponse.json();

      if (checkResult.exists) {
        // If the email is already registered, show an error
        setError(true);
        alert("Email is already registered.");
        return;
      } else {
        setError(false);
      }

      // Get the email by promo code
      let registeredByEmail = registeredBy;
      if (promoCode) {
        let promoResponse = await fetch(`${getBaseURL()}/api/getEmailByPromoCode?promoCode=${promoCode}`);
        let promoResult = await promoResponse.json();
        if (promoResult.success) {
          registeredByEmail = promoResult.email;

        } else {
          alert('Invalid Promo Code');
          return;
        }
      }
      // Navigate to PaymentMode component with the registration data
      navigate('/PaymentMode', {
        state: {
          email,
          name,
          city,
          contact,
          chanting,
          facilitator,
          occupation,
          initiatedName,
          selectedOption,
          registeredBy: registeredByEmail,
          dob,
          promoCode,
        }
      });
    } catch (err) {
      console.error("Error during registration:", err);
      alert("An error occurred during registration. Please try again.");
    }
  };

  const moveSlider = (index) => {
    setActiveIndex(index);
  };

  return (
    <main>
      <div className="box light-border">
        <div className="inner-box">
          <div className="carousel">
            <div className="images-wrapper">
              <img src={img2} className={`image img-1 ${activeIndex === 1 ? 'show' : ''}`} alt="" />
              <img src={img3} className={`image img-2 ${activeIndex === 2 ? 'show' : ''}`} alt="" />
              <img src={img2} className={`image img-3 ${activeIndex === 3 ? 'show' : ''}`} alt="" />
            </div>
          </div>
          <div className="forms-wrap">
            <form autoComplete="off" onSubmit={handleSignup} className="sign-in-form">
              <div className="logo">
                <img src={img1} alt="hare krishna" />
              </div>
              <div className="heading">
                <h3>Welcome to Viplava 2024</h3>
                <h3>Registrations Closed</h3>
                {/* <h6>If not registered then please register you have very less time?</h6> */}
              </div>

              <div className="actual-form">
                <div className="input-wrap">
                  <input
                    type="text"
                    placeholder="Email id (required)"
                    className="input-field"
                    value={email}
                    onChange={(event) => setEmail(event.target.value)}
                    required
                  />
                  {/* {
                    error && !email && <span className="input-error">Please enter valid email.</span>
                  } */}

                  {
                    emailError && <span className="input-error">{emailError}</span>
                  }

                </div>

                <div className="input-wrap">
                  <input
                    type="text"
                    placeholder="Name (required)"
                    className="input-field"
                    value={name}
                    onChange={(event) => setName(event.target.value)}
                    required
                  />
                  {/* {
                    error && !name && <span className="input-error">Please enter valid name.</span>
                  } */}

                </div>

                <div className="input-wrap">
                  <input
                    type="text"
                    placeholder="Initiated name"
                    className="input-field"
                    value={initiatedName}
                    onChange={(event) => setInitiatedName(event.target.value)}
                    required
                  />

                </div>

                <div className="input-wrap">
                  <select
                    className="input-field"
                    value={city}
                    onChange={(event) => setCity(event.target.value)}
                    required
                  >
                    <option value="" disabled>Select Area (required)</option>
                    {areasList.map((area) => (
                      <option key={area} value={area}>
                        {area}
                      </option>
                    ))}
                  </select>

                </div>

                <div className="input-wrap">
                  <input
                    type="text"
                    placeholder="Phone no. (required)"
                    className="input-field"
                    value={contact}
                    onChange={handleContactChange}
                    required
                  />
                  {/* {
                    error && !contact && <span className="input-error">Please enter valid contact.</span>
                  } */}

                </div>
                <div className="input-wrap">
                  <input
                    type="text"
                    placeholder="Number of Rounds of Chanting (required)"
                    className="input-field"
                    value={chanting}
                    onChange={handleChantingChange}
                    required
                  />
                  {/* {
                    error && !chanting && <span className="input-error">Please enter valid number of chanting rounds.</span>
                  } */}
                </div>

                <div className="input-wrap">
                  <select
                    className="input-field"
                    value={facilitator}
                    onChange={(event) => setFacilitator(event.target.value)}
                    required
                  >
                    <option value="" disabled>Select Facilitator (required)</option>
                    {facilitatorsList.map((facilitator) => (
                      <option key={facilitator.name} value={facilitator.name}>
                        {facilitator.name}
                      </option>
                    ))}
                  </select>
                  {/* {error && !facilitator && <span className="input-error">Please select a facilitator.</span>} */}
                </div>

                <div className="input-wrap">
                  <select
                    className="input-field"
                    value={occupation}
                    onChange={(event) => setOccupation(event.target.value)}
                    required
                  >
                    <option value="" disabled>Select Occupation (required)</option>
                    <option value="student">Student</option>
                    <option value="job">Job</option>
                    <option value="business">Business</option>
                    <option value="unemployed">Unemployed</option>
                  </select>
                  {/* {
                    error && !occupation && <span className="input-error">Please select your occupation.</span>
                  } */}
                </div>

                {!promoCode && (
                  <div className="input-wrap">
                    <input
                      type="text"
                      placeholder="Registered by Email"
                      className="input-field"
                      value={registeredBy}
                      onChange={(event) => setRegisteredBy(event.target.value)}
                    />
                    {/* {error && !registeredBy && <span className="input-error">Please enter valid email.</span>} */}
                  </div>
                )}

                <div className="input-wrap">
                  <input
                    type="date"
                    placeholder="Date of Birth (required)"
                    className="input-field"
                    value={dob}
                    onChange={(event) => setDob(event.target.value)}
                    min={minDate}
                    max={maxDate}
                    required
                  />
                  
                  
                  {/* {
                    error && !dob && <span  className="input-error">Please enter a valid date of birth.</span>
                  } */}
                </div>
                {/* Gender */}
                <div className="input-wrap">
                  <div className="dhoti-kurta-box">
                    <p>Do you want dhoti kurta?</p>
                    <p class="disclaimer">
                      Note: The registration cost is 600 Rs. If you select "Yes," an additional 400 Rs will be added to your total registration cost.
                    </p>
                    <div className="radio-wrapper radio-btn">
                      <label>
                        <input
                          type="radio"
                          value="yes"
                          checked={selectedOption === 'yes'}
                          onChange={handleOptionChange}
                        />
                        Yes
                      </label>
                      <label className='m-l'>
                        <input
                          type="radio"
                          value="no"
                          checked={selectedOption === 'no'}
                          onChange={handleOptionChange}
                        />
                        No
                      </label>
                    </div>
                  </div>
                  {/* {error && !selectedOption && <span className="error">Please choose options for Dhoti Kurta!</span>} */}
                  <div className='errorpopup'>
                {error && <p className="error">{errorMessage}</p>}
                </div>
                
                  <input type="submit" value="Register" className="sign-btn" disabled onClick={handleSignup} />
                
                </div>
                
                
              </div>
            </form>
          </div>
        </div>
      </div>
    </main>
  );
};

export default App;
