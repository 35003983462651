import React, { useState } from 'react'
import {AppBar,Toolbar,Typography,Box,Tabs,Tab} from '@mui/material'
import {Link} from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import axios  from 'axios'
import { authActions } from '../store'
axios.defaults.withCredentials=true;

const Header = () => {
  const dispatch=useDispatch();
  const isLoggedIn=useSelector(state=>state.isLoggedIn);
    const [value,setValue] =useState();
    const getBaseURL = () => {
      const host = window.location.host;
      if (host.includes('localhost')) {
        return 'http://localhost:5000'; // Local API URL
      } else {
        return 'https://iyfghaziabad.in'; // Production API URL
      }
    };
    const sendLogoutReq= async () => {
      const res=await axios.post(`${getBaseURL()}/api/logout`,null,{
        withCredentials:true,
      });
      if(res.status==200){
        return res;
      }
      return new Error("Unable to Logout.Please try again")
    }
    const handleLogout=() => {
      localStorage.setItem('isLoggedIn', 'false');
      sendLogoutReq().then(()=>dispatch(authActions.logout()));
    }
  return (
    <div><AppBar position='sticky'>
          <Toolbar><Typography variant="h3">
            IYF GHAZIABAD
            </Typography>
            <Box sx={{marginLeft:"auto"}}>
                <Tabs indicatorColor='secondary' 
                 onChange={(e,val)=>setValue(val)} 
                  value={value} 
                textColor="inherit">
                     { !isLoggedIn && <Tab to="/login" LinkComponent={Link} label="Login"/>
                     } 
                     {/* <Tab to="/signup" LinkComponent={Link} label="Signup"/> */}
                   {isLoggedIn &&( <Tab onClick={handleLogout} to="/header2" LinkComponent={Link} label="Logout"/>)}{" "}
                </Tabs>
            </Box>
            </Toolbar>
          </AppBar>
  
        </div>
  )
}

export default Header