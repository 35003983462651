import React from 'react';
import { useEffect, useState } from 'react';
import "./style.scss";

const Timer = ({duration}) => {
    const [time,setTime] = useState(duration);

    useEffect(()=>{
        setTimeout(()=>{
            setTime(time-1000);
        },1000)
    },[time])

    const getFormattedTime = (milliseconds) =>{
        // let total_seconds = parseInt(Math.floor(milliseconds/1000));
        // let total_minutes = parseInt(Math.floor(total_seconds/60));
        // let total_hours = parseInt(Math.floor(total_minutes/60));
        // let total_days = parseInt(Math.floor(total_hours/24));
        // let months = parseInt(Math.floor(total_days/30));

        // let seconds = parseInt(total_seconds %60);
        // let minutes = parseInt(total_minutes %60);
        // let hours = parseInt(total_hours %24);
        // let days = parseInt(total_days %30);

        // return `${months}: ${days} : ${hours} : ${minutes}: ${seconds}`

        const oneMinute = 1000 * 60; // milliseconds in a minute
        const oneHour = oneMinute * 60; // milliseconds in an hour
        const oneDay = oneHour * 24; // milliseconds in a day
      
        // Calculate days, hours, and minutes
        const days = Math.floor(milliseconds / oneDay);
        const hours = Math.floor((milliseconds % oneDay) / oneHour);
        const minutes = Math.floor((milliseconds % oneHour) / oneMinute);
      

        return <p style={{padding:'0',margin:'0'}}><span className='colorme'>{days}</span> Days | <span className='colorme'>{hours}</span> hours | <span className='colorme'>{minutes}</span> minutes</p>
    }
  return (
    <div className='Time'>{getFormattedTime(time)}</div>
  )
}

export default Timer;