import React, { useEffect, useState } from 'react';
import Axios from 'axios';

Axios.defaults.withCredentials = true;

const Welcome = () => {
  const [user, setUser] = useState();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  let firstRender = true;
  const getBaseURL = () => {
    const host = window.location.host;
  
    if (host.includes('localhost')) {
      return 'http://localhost:5000'; // Local API URL
    } else {
      return 'https://iyfghaziabad.in'; // Production API URL
    }
  };
  const refreshToken = async () => {
    try {
      const res = await Axios.get(`${getBaseURL()}/api/refresh`, {
        withCredentials: true
      });
      return res.data; // Make sure you're accessing the right response structure
    } catch (err) {
      console.error("Error refreshing token", err);
      setError("Session expired. Please log in again.");
    }
  };

  // const sendRequest = async () => {
  //   try {
  //     const res = await axios.get('http://localhost:5000/api/user', {
  //       withCredentials: true,
  //     });
  //     console.log(res.body);
  //     console.log(res.statusCode);
  //     return res.data; // Ensure you're accessing the correct response data
  //   } catch (err) {
  //     console.error("Error fetching user data", err);
  //     setError("Failed to fetch user data.");
  //   }
  // };
  const sendRequest = async () =>{
    const res= await Axios.get(`${getBaseURL()}/api/user`,{
      withCredentials: true,
    }).catch((err) => console.log(err));
    const data=await res.data;
    return data;
  }
  useEffect(() => {
    if (firstRender) {
      firstRender = false;
      sendRequest()
        .then(data => {
          setUser(data?.user);
          setLoading(false);
        })
        .catch(err => {
          setLoading(false);
          setError("Failed to load user data.");
        });
    }

    // Token refresh interval (every 29 seconds)
    const interval = setInterval(() => {
      refreshToken().then(data => setUser(data.user));
    }, 1000*25);
    return () => clearInterval(interval); // Cleanup the interval on unmount
  }, []);

  if (loading) {
    return <h1>Loading user data...</h1>;
  }

  if (error) {
    return <h1>{error}</h1>;
  }

  return (
    <div>
      {user ? <h1>Welcome, {user.name}</h1> : <h1>No user found</h1>}
    </div>
  );
}

export default Welcome;
