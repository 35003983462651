import React from 'react';
import './style.scss';

const Thumbnail = ({ images, itemActive, onThumbnailClick }) => {
  return (
    <div className="thumbnail">
      {images.map((image, index) => (
        <div
          key={index}
          className={`item ${index === itemActive ? 'active' : ''}`}
          onClick={() => onThumbnailClick(index)}
        >
          <img src={image.src} alt={image.title} />
          <div className="content">{image.title}</div>
        </div>
      ))}
    </div>
  );
};

export default Thumbnail;
