export const COLUMNS = (cb, {disabled, isAdmin, isAttendance}) => [
  {
    name: "ID",
    selector: "uuid",
    sortable: true,
    width: "100px"
  },
  {
    name: "Name",
    selector: "name",
    sortable: true,
  },
  {
    name: "Email",
    selector: "email",
    sortable: true,
  },
  {
    name: "Contact",
    selector: "contact",
    sortable: true,
  },
  // {
  //     name: 'Gender',
  //     selector: 'gender',
  //     sortable: true,
  // },
  {
    name: "Registered By",
    selector: "registeredBy",
    sortable: true,
  },
  {
    name: "Remarks",
    selector: "remarks",
    sortable: true,
  },
  {
    name: "Bhagavad Gita",
    selector: (devotee) => {
      return devotee.isBgIncluded ? "Yes" : "No";
    },
    sortable: true,
  },
  {
    name: "Action",
    button: true,
    width: "200px",
    cell: (row) => (
      <div>
        <button
          style={{
            border: "1px solid black",
            margin: "2px",
            borderRadius: "4px",
          }}
          onClick={() => {
            cb("view", row);
          }}
        >
          View
        </button>
        <button
          style={{
            border: "1px solid black",
            margin: "2px",
            borderRadius: "4px",
          }}
          onClick={() => {
            cb("edit", row);
          }}
        >
          Edit
        </button>
        {!!isAdmin && <button
          style={{
            border: "1px solid black",
            margin: "2px",
            borderRadius: "4px",
          }}
          disabled={disabled}
          onClick={() => {
            cb("resendEmail", row);
          }}
        >
          Resend Email
        </button>}
       {!!isAttendance && <button
          style={{
            color: "white",
            background: disabled
              ? "#666"
              : row.attendance === "absent"
              ? "#500"
              : "#050",
            border: "1px solid black",
            margin: "2px",
            borderRadius: "4px",
          }}
          disabled={disabled}
          onClick={() => {
            cb("attend", row);
          }}
        >
          {disabled
            ? "Please Wait..."
            : row.attendance === "absent"
            ? "Mark Present"
            : "Mark Absent"}
        </button>}
      </div>
    ),
  },
];
