import React from "react";
import Layout from "../../components/Layout";

const RefundContainer = () => (
  <Layout title="Refund Policy">
    <div>
      <p>
        In case of any cancellation/refund request to be made, please reach out
        to connecttokrishnanow@gmail.com. If the grievance still not addressed,
        Please Reach out to our Technical In-charge RAVI GARG at 9953522058 or
        the concerned person provided at the event poster itself. Usually for
        all the events the maximum refund you can get is 50% refund on
        cancelllation.
      </p>
      <p>
        Refund will be initiated with 24 hours of refund rquest. If the actions
        not taken place, please mail us at our contact email id.
      </p>
      <p>
        In some specific cases, no amount would be refunded. In such cases, it
        would be clearly mentioned at the time of event registration itself.
      </p>
    </div>
  </Layout>
);

export default RefundContainer;
