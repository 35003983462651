export const ROUTE = {
    HOME: '/',
    LOGIN: '/login',
    BLOG: '/blog/:slug',
    CONTACT_US: '/contact-us',
    WISDOM_BATCH: '/wisdom-batch',
    PRIVACY: '/privacy',
    UTSAH: '/utsah',
    EVENT_VOLUNTEER: '/event/volunteer',
    EVENT_LIST: '/event/list',
    ABOUT: '/about',
    TERMS: '/terms',
    REFUND: '/refund',
    DYS: '/dys',
    DYS_LIST: '/dys/list',
    DYS_LIST_SESSIN: '/dys/list/:session_id',
    REGISTRATION_FORM: '/registration',
    PAYMENT_MODE: '/paymentmode',
    SUCCESS: '/success',
    ONLINESUCCESS: '/onlinesuccess'


}

export const CREATE_NEW_REGISTRATION = '/user/register';
export const FETCH_VOLUNTEER_LIST = '/user/volunteers';
export const FETCH_ALL_USERS = '/user/fetchAll';
export const MARK_ATTENDANCE = '/user/markAttendance';
export const UPDATE_USER = '/user/update';
export const DYS_LIST = 'api/devotee/list';
