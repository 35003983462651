import React from "react";
import FooterComponent from "../../components/Footer";
import Header from "../../components/Header";
import Slider from "../../components/Slider";
import Timer from "../../components/Timer";
import "./style.scss";
import { useState, useEffect } from "react";
import ScrollAnimation from "../../components/ScrollAnimation";
import Price from "../../components/Price";
// import { Card } from "react-bootstrap";

const UmangContainer = () => {
  const slider1Images = [
    "https://res.cloudinary.com/dwneljbds/image/upload/v1723793745/image_2_v6shyj.png",
    "images/slider1_2.png",
    "images/slider1_3.png",
    "images/slider1_4.png",
    "images/slider1_5.png",
    "images/slider1_6.png",
    "images/slider1_7.png",
    "images/slider1_8.png",
    "images/slider1_9.png",
    "images/slider1_10.png",
  ];
  const [scale, setScale] = useState(1);
  const [isTranslated, setIsTranslated] = useState(false);
  const [textVisible, setIsVisible] = useState(false);
  const [isFixed, setIsFixed] = useState(false);
  const [isMobileView, setIsMobileView] = useState(window.innerWidth <= 768);

  const handleScroll = () => {
    const scrollY = window.scrollY;
    const viewportHeight = window.innerHeight;

        // Calculate the scale factor
        if(!isMobileView){
          const newScale = Math.max(0.3, 1 - (scrollY*1.5 / (viewportHeight * 2)));
          setScale(newScale);
          console.log(newScale)
          if (newScale === 0.3) {
            setIsTranslated(true);
            setIsFixed(true);
            setIsVisible(true);
        } else {
            setIsTranslated(false);
            setIsFixed(false);
            setIsVisible(false);
        }
          }
          else{
          const newScale = Math.max(0.4, 1 - (scrollY*1.5 / (viewportHeight * 2)));
          setScale(newScale);
          console.log(newScale)
          if (newScale === 0.4) {
            setIsTranslated(true);
            setIsFixed(true);
            setIsVisible(true);
        } else {
            setIsTranslated(false);
            setIsFixed(false);
            setIsVisible(false);
        }

          }

    // // Calculate the left position for the video
    // const newPosition = Math.min(0, -scrollY / 2);
    // setPosition(newPosition);
  };

    useEffect(() => {
      const handleResize = () => {
        setIsMobileView(window.innerWidth <= 1070);
    };
        window.addEventListener('scroll', handleScroll);
        window.addEventListener('resize', handleResize);

        // Cleanup the event listener on component unmount
        return () => {
            window.removeEventListener('scroll', handleScroll);
            window.removeEventListener('resize', handleResize);
        };
    }, [isMobileView]);

  return (
    <div>
      <div className="umang-landing-page">
        <div className="banner">
          <Header />
          <div className="video-base">
          <video
          id='video'
          className="umang-bg-video"
          playsInline
          muted
          loop
          autoPlay
          width="100%"
          style={{
            transform: isMobileView && isFixed
                ? `scale(${scale * 1.6}, ${scale * 0.55})` // Shrink vertically for landscape effect
                : `scale(${scale})`,
            transformOrigin: isMobileView ? 'center 12rem' : '25% 42%',
            transition: 'transform 0.3s ease'
        }}
          >
            <source
              src="https://res.cloudinary.com/dwneljbds/video/upload/v1724406195/1_tmjpzf.mp4"
              type="video/mp4"
              />
              Your browser does not support HTML5 video.
            </video>
            <div className="overlay"></div>
            <div className={`text-content ${textVisible ? "visible" : ""}`}>
              <div className="about-fest">
                <div className="title">About the Fest</div>
                <p>
                  The word "Viplava" literally means revolution. So the ISKCON
                  is celebrating VIPLAVA so as to bring Revolution or
                  transformation in the Consciousness of our youth, therby
                  leading to their overall well-being. It will take place on
                  27th October 2024 at Srinath Banquet Hall in Ghaziabad, for
                  1000+ youth from across the Uttar Pradesh to inspire and
                  empower them.
                </p>
              </div>
              <div className="timer">
                <h4>Event starts in</h4>
                <Timer duration={3373200000} style={{}} />
              </div>
            </div>
          </div>
          <div className="banner-overlay">
            <div
              className="title"
              style={{
                transform: `${
                  textVisible ? "translateY(-100%)" : "translateY(0)"
                }`,
                color: `${textVisible ? "#921A40" : ""}`,
                transition: "0.3s ease",
              }}
            >
              <h2>Viplava</h2>
              <p>2024</p>
            </div>
            <div
              className="line-1"
              style={{
                opacity: `${textVisible ? "0" : "1"}`,
                transition: "0.3s ease",
              }}
            >
              A REVOLUTION IN CONSCIOUSNESS
            </div>
            <div
              className="line-2"
              style={{
                opacity: `${textVisible ? "0" : "1"}`,
                transition: "0.3s ease",
              }}
            >
              27 October 2024 / Srinath Banquet, Ghaziabad / 9 am ONWARDS
            </div>
            <a
              href="/registration"
              target="_self"
             
            >
              <button
                className="register-btn round-btn"
                style={{
                  backgroundcolor: "transparent",
                  color: "rgb(128, 0, 10)",
                  border: "2px solid rgb(255, 255, 255)",
                  opacity: `${textVisible ? "0" : "1"}`,
                  transition: "0.3s ease",
                }}
              >
                REGISTER NOW
              </button>
            </a>
          </div>
        </div>
        <div className="blank"></div>
        <Slider stack={1} />
        <div className="fest-details">
          <div className="part">
            <div className="up-title">THEME</div>
            <div className="title">Remembering Our Lost Legends !</div>
            <p className="para">
              In tribute to Srila Prabhupada and his esteemed disciples, we
              honor the enduring legacy of these spiritual luminaries with deep
              reverence. Their profound teachings, compassionate guidance, and
              unwavering dedication have left an indelible mark on our hearts
              and souls. As we reflect on their noble contributions, may their
              divine wisdom continue to illuminate our paths, inspire our
              actions, and strengthen our commitment to their sacred vision.
              Through remembering their lives and works, we rekindle our own
              spiritual aspirations and deepen our connection to the eternal
              truth they so valiantly upheld.
            </p>
          </div>
          <div className="part poster">
            <img src="https://res.cloudinary.com/dwneljbds/image/upload/v1725968715/initiation_awvydw.jpg" />
          </div>
        </div>
        <div className="speaker-details">
          <ScrollAnimation />
        </div>
        <div className="Glimpses" style={{ backgroundColor: "white" }}>
          <Slider stack={2} />
        </div>
        <Price/>
      </div>
      <div
        style={{
          height: "0vh",
          backgroundColor: "white",
          position: "relative",
          zIndex: "2",
        }}
      ></div>

      <FooterComponent />
    </div>
  );
};

export default UmangContainer;
