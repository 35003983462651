import React from 'react'
import { useState } from 'react';
import {Box,Button,TextField,Typography} from '@mui/material';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
const Signup = () => {
  const history=useNavigate();
  const [inputs,setInputs] = useState({
    name:"",
    email:"",
    password:""
  })
  const handleChange=(e) => {
    setInputs({...inputs,[e.target.name]:e.target.value})
  };
  const getBaseURL = () => {
    const host = window.location.host;
  
    if (host.includes('localhost')) {
      return 'http://localhost:5000'; // Local API URL
    } else {
      return 'https://iyfghaziabad.in'; // Production API URL
    }
  };
  const sendRequest = async () =>{
    const res= await axios.post(`${getBaseURL()}/api/signup`,{
      name:inputs.name,
      email:inputs.email,
      password:inputs.password,
    }).catch((err) => console.log(err));
    const data=await res.data;
    return data;
  }
  const handleSubmit=(e) => {
    e.preventDefault();
    //send http request
    sendRequest().then(()=>history("/login"));
  };
  return (
    <div>
      <form onSubmit={handleSubmit}>
      <Box marginLeft='auto' marginRight='auto' 
      justifyContent='center' alignItems='center' 
      width={300} display='flex' flexDirection={'column'} >
        <Typography  variant='h2'>Signup</Typography>
        <TextField name='name' onChange={handleChange} value={inputs.name} variant="outlined" placeholder='Name' margin="normal"/>
        <TextField name='email' onChange={handleChange} type='email' value={inputs.email} variant="outlined" placeholder='Email' margin='normal' />
        <TextField name='password' onChange={handleChange} type='password' value={inputs.password} variant="outlined" placeholder='Password' margin='normal' />
        {/* <TextField name='role' onChange={handleChange} type='role' value={inputs.role} variant="outlined" placeholder='role' margin='normal' /> */}
        <Button variant='contained' type="submit">Signup</Button>
      </Box>
      </form></div>
  )
}

export default Signup